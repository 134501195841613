._3z7tYe8o5dCyBjZ7ySbWvv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem; }
  ._3z7tYe8o5dCyBjZ7ySbWvv ._1mWZb6QG9ryYPo0h-ugcCp {
    display: flex;
    justify-content: space-between;
    width: 110px;
    align-items: center !important;
    margin-top: -5px !important; }
    ._3z7tYe8o5dCyBjZ7ySbWvv ._1mWZb6QG9ryYPo0h-ugcCp div ._3Fdkkkp3NylYr0T7pGLdWl {
      height: 16px;
      width: 14px; }
  ._3z7tYe8o5dCyBjZ7ySbWvv ._3Fdkkkp3NylYr0T7pGLdWl {
    color: black;
    text-align: center;
    font-size: 0.9375rem;
    background-color: white; }
  ._3z7tYe8o5dCyBjZ7ySbWvv ._1EsEA-o4_-5AOrvHu3Tw6x {
    margin-top: 20px; }

._1AwgRmllATDGJi5msDlyrK {
  position: relative; }

.LJadTn26VeMZc4IvI4jpM {
  position: absolute;
  left: 17px;
  top: -7px;
  background: orange;
  color: black !important;
  border-radius: 20px;
  width: 17px;
  height: 17px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  padding-top: 1px;
  padding-right: 1px; }

._1pbwok6uU4eGDmN683KORg {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  ._1pbwok6uU4eGDmN683KORg ._16GuV_N1BTAk7c3vo2O4C1 {
    display: flex;
    height: 100%;
    padding: 4px 8px;
    border-bottom: 1px solid #d8d8d8; }
    ._1pbwok6uU4eGDmN683KORg ._16GuV_N1BTAk7c3vo2O4C1 ._2WsGlKdKcfeOqGUJPyNxEU {
      height: 32px;
      width: 100%; }

._2scEYz42GgwA7yeSkbJwAS {
  outline: none;
  border: none;
  background: transparent;
  font-size: 25px;
  margin: 12px;
  color: #fff; }

._2Bo7PDkBXV627DPggjvLBZ {
  display: flex;
  width: 83px;
  justify-content: space-between;
  align-items: center; }

._1daAL4sioOF5Ng5jMXadYk {
  display: flex;
  width: 55px;
  justify-content: space-between;
  align-items: self-end; }
