._3hJYoA3N9vGiXFdzGByiAl {
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: fit-content; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez {
    background-color: #fff;
    border: 1px solid #979797;
    height: 21px;
    margin-right: 10px;
    width: 30px; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez[aria-checked='true'] {
    background-color: #01a3e5; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez:after {
    border: solid white;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 13px;
    left: 6px;
    top: auto;
    position: relative;
    transform: rotate(45deg);
    width: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg); }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez[aria-checked='true']:after {
    display: block; }
  ._3hJYoA3N9vGiXFdzGByiAl ._2-ltAIyRR8dBBOhSfFMaOg {
    display: flex;
    flex-direction: column; }
    ._3hJYoA3N9vGiXFdzGByiAl ._2-ltAIyRR8dBBOhSfFMaOg img {
      max-height: 28px;
      max-width: 28px; }
  @media (max-width: 768px) {
    ._3hJYoA3N9vGiXFdzGByiAl ._2-ltAIyRR8dBBOhSfFMaOg {
      flex-direction: row; }
    ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez {
      width: 24px;
      align-self: center; } }
  ._3hJYoA3N9vGiXFdzGByiAl .C3aTRPu_vyjCTEtALBqMM {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 5px; }
    ._3hJYoA3N9vGiXFdzGByiAl .C3aTRPu_vyjCTEtALBqMM a {
      color: #337ca3; }
  ._3hJYoA3N9vGiXFdzGByiAl input {
    display: none; }

@media (min-width: 441px) {
  ._3hJYoA3N9vGiXFdzGByiAl ._2-ltAIyRR8dBBOhSfFMaOg {
    flex-direction: row; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez {
    width: 21px; } }

._2oUZoYh-SCDilT6QwwS7Fj {
  background: white;
  padding: 15px;
  margin-bottom: 15px; }

._3lGgQv2ILIZ1MShpHkMp0k {
  color: red; }

._1mMJhpczfwGHcYTv_VhH3D {
  padding-top: 10px;
  text-align: center;
  color: red; }

.DpnF35BDnFH5kLZSlEnQr {
  padding-top: 10px;
  text-align: center; }

._2Vo-U4W8DdDx1u2Bn0raZg {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center; }
  ._2Vo-U4W8DdDx1u2Bn0raZg button {
    width: 100%;
    margin-bottom: 10px; }

._17eWw3c1Zc1qlgLWlDo6IP {
  margin-bottom: 30px; }

._1o1kYebkL6iyQ8YUgNSVg2 {
  display: flex; }

._3mh1Vmh61vbqGMBqIdu96t {
  padding: 15px;
  padding-left: 80px;
  padding-right: 80px;
  background: #1ba4e2;
  color: white;
  text-transform: uppercase;
  transition: 0.3s all;
  cursor: pointer;
  border-radius: 30px; }
  ._3mh1Vmh61vbqGMBqIdu96t h2 {
    color: white; }
  ._3mh1Vmh61vbqGMBqIdu96t:hover {
    background: white; }
    ._3mh1Vmh61vbqGMBqIdu96t:hover h2 {
      color: #1ba4e2; }
  ._3mh1Vmh61vbqGMBqIdu96t:focus {
    outline: 0; }

._28JXZiFqoBiL8A0J7NH-nF {
  padding: 10px;
  background: #1075b1;
  margin-bottom: 15px; }
  ._28JXZiFqoBiL8A0J7NH-nF h1,
  ._28JXZiFqoBiL8A0J7NH-nF h2 {
    font-family: Nunito;
    color: white; }

._3ZQkbpwky3h2rNs_RIKVfZ {
  padding: 10px;
  background: #e9e9e9;
  margin-bottom: 15px;
  color: black; }
  ._3ZQkbpwky3h2rNs_RIKVfZ h3 {
    font-family: Nunito; }

.qNqu_potr6HAu5H_JRGme {
  padding: 10px;
  background: #f7f7f7;
  margin-bottom: 15px; }

.LkXIR3qeTM9bHSbN4146Z {
  margin-bottom: 15px; }

._33M01LCj-_t1BfI8VW0A1v {
  border-bottom: 1px solid #eee; }

.-ubWWDMuNdkqI1_iyCy2u {
  display: flex;
  background: white;
  padding: 15px 0;
  border-bottom: 1px solid #eee; }
  .-ubWWDMuNdkqI1_iyCy2u ._21NvtSNAlhuKsi84hUfH2Y {
    border: 1px solid #d8d8d8;
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .-ubWWDMuNdkqI1_iyCy2u ._21NvtSNAlhuKsi84hUfH2Y img {
      max-width: 131px; }
  .-ubWWDMuNdkqI1_iyCy2u img {
    max-width: 100%;
    max-height: 100%; }

._518NC3-w-UfvrtEB0rC29 {
  background: white;
  padding: 15px; }

._2UHdX0mlT9-LX_m61L9DXQ {
  padding-left: 15px; }
  ._2UHdX0mlT9-LX_m61L9DXQ .wIxT_SS1Oh7NktaOepJFg {
    font-size: 0.875rem;
    margin-bottom: 10px; }
  ._2UHdX0mlT9-LX_m61L9DXQ ._1gAtCJbc8QGsNKlVeCOAIC {
    font-size: 0.875rem; }
  ._2UHdX0mlT9-LX_m61L9DXQ * {
    margin-bottom: 5px;
    line-height: 20px; }

.bigmEkwfnwA-S_WYWRr-O {
  margin-bottom: 15px;
  border-bottom: 3px solid #01a3e5;
  display: inline-block; }

._36NdycABXUbE_cjzc-sji8 {
  margin-bottom: 15px; }

.mJTcwL-7PAjjoo9DhaTjj {
  display: flex;
  padding: 15px; }
  .mJTcwL-7PAjjoo9DhaTjj ._3xLNOrBWw1nG08uFXGIPjH ._1PzH8h8W2Cq42VIrD1yX3j {
    color: #417505;
    font-weight: bold; }

.-sbucvUbkL6KMIILKUOVx {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.-sbucvUbkL6KMIILKUOVx input {
  display: none; }

.jSQU810PxAB4dZNa5agqv {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #979797; }

.-sbucvUbkL6KMIILKUOVx:hover input ~ .jSQU810PxAB4dZNa5agqv {
  background-color: #eee; }

.-sbucvUbkL6KMIILKUOVx input:checked ~ .jSQU810PxAB4dZNa5agqv {
  background-color: #fff; }

.jSQU810PxAB4dZNa5agqv:after {
  content: '';
  position: absolute;
  display: none; }

.-sbucvUbkL6KMIILKUOVx input:checked ~ .jSQU810PxAB4dZNa5agqv:after {
  display: block; }

.-sbucvUbkL6KMIILKUOVx .jSQU810PxAB4dZNa5agqv:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #01a3e5; }

.iadxk-C8bU7IB6Zv1EUnr {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.iadxk-C8bU7IB6Zv1EUnr input {
  display: none; }

.nntXiGxQg15WE6tFZARez {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border: 1px solid #979797; }

.iadxk-C8bU7IB6Zv1EUnr:hover input ~ .nntXiGxQg15WE6tFZARez {
  background-color: #ccc; }

.iadxk-C8bU7IB6Zv1EUnr input:checked ~ .nntXiGxQg15WE6tFZARez {
  background-color: #01a3e5; }

.nntXiGxQg15WE6tFZARez:after {
  content: '';
  position: absolute;
  display: none; }

.iadxk-C8bU7IB6Zv1EUnr input:checked ~ .nntXiGxQg15WE6tFZARez:after {
  display: block; }

.iadxk-C8bU7IB6Zv1EUnr .nntXiGxQg15WE6tFZARez:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

._2wzYOTJPmdhU7XbKDh52rf {
  opacity: 0.3;
  cursor: not-allowed; }

@media (max-width: 768px) {
  .-ubWWDMuNdkqI1_iyCy2u {
    flex-direction: column;
    align-items: center; }
  ._2UHdX0mlT9-LX_m61L9DXQ {
    margin-top: 15px; } }

.o7GdDEqMfOMnlq_1mjbfz {
  padding: 15px 0; }
  .o7GdDEqMfOMnlq_1mjbfz ._1-lAF8D_fCmoDHrKlpgMwi {
    white-space: nowrap;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    transition: 0.3s all; }
    .o7GdDEqMfOMnlq_1mjbfz ._1-lAF8D_fCmoDHrKlpgMwi i {
      margin-left: 2px; }
    .o7GdDEqMfOMnlq_1mjbfz ._1-lAF8D_fCmoDHrKlpgMwi:focus {
      outline: 0; }
  .o7GdDEqMfOMnlq_1mjbfz .GN_4h8yNE_FfFui0CSOlW {
    margin: 15px 0;
    padding: 8px;
    width: 62%;
    font-size: 1rem;
    border: 1px solid #dbdbdb;
    color: #0000007d; }
    .o7GdDEqMfOMnlq_1mjbfz .GN_4h8yNE_FfFui0CSOlW:focus {
      outline: 0; }
    .o7GdDEqMfOMnlq_1mjbfz .GN_4h8yNE_FfFui0CSOlW::placeholder {
      font-size: 1.1rem; }

._1RoCGDnz-KGpvmSzLjW3iO {
  padding-bottom: 10px; }

._3q_9FtmZsy5EYeinYGDUcV {
  padding-left: 33px; }
  ._3q_9FtmZsy5EYeinYGDUcV input {
    font-size: 16px; }

._30_cbetZXVtsaqFSGd-tvO {
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px; }

.cep6igmMpJXwz1vcnUN08 {
  padding-bottom: 12px;
  padding-top: 12px; }

._1mr1ZuHacZ34PhGYnITKcr {
  padding-bottom: 12px;
  padding-top: 12px; }

._15-vozstRnJ_MdB2iUf_J_ {
  border-top: 1px solid black;
  padding-bottom: 12px;
  padding-top: 12px; }

._1yWLpsNGeFAeqzNTmLPZgg {
  font-size: 0.8rem;
  padding-bottom: 10px; }

.B7Yf80TH8oxJYvNZAJ9py {
  font-size: 0.8rem;
  align-self: center;
  padding-left: 6px;
  color: #3a6284;
  font-weight: bold; }

._27-mzgmHwqoKwMlt2bepFP {
  display: flex;
  padding-left: 6px;
  padding-bottom: 10px; }

._3j5bvrBui7DFhj36NfLWgF {
  opacity: 0.5;
  pointer-events: none; }

.PBxU9U50P82cw_ap_u8ef {
  border: 1px solid #ccc;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 15px; }
  .PBxU9U50P82cw_ap_u8ef select {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    height: 42px;
    color: #767676;
    outline: none;
    border: 0px;
    font-family: Nunito; }

._3s5viKx2MV7msI3iFZSd4w {
  width: 90px;
  height: 90px;
  border: 2px solid #7e7e7e;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px; }

.i9JBK0bjJJUUfg1Ehrd3e {
  display: flex;
  justify-content: space-around; }
  .i9JBK0bjJJUUfg1Ehrd3e img {
    width: 35px;
    height: 26px;
    border-radius: 2px; }

.iQe_svyaluJhwLeNisoLY {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 190px; }
  .iQe_svyaluJhwLeNisoLY img {
    border-radius: 2px;
    height: 30px; }

._2iy1sIInEAjm0KdzKO5Jvz {
  width: 100%; }
  ._2iy1sIInEAjm0KdzKO5Jvz ._5akHZUiEQ6srGYNBRUUgb {
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    font-size: 0.9rem; }
    ._2iy1sIInEAjm0KdzKO5Jvz ._5akHZUiEQ6srGYNBRUUgb ._3o5bDTdptngMFho3rvRY9u {
      align-self: flex-start;
      width: 25%; }
    ._2iy1sIInEAjm0KdzKO5Jvz ._5akHZUiEQ6srGYNBRUUgb ._2g646JA3eGrRFgB1O4ht2T {
      align-self: flex-start;
      width: 18%; }
    ._2iy1sIInEAjm0KdzKO5Jvz ._5akHZUiEQ6srGYNBRUUgb ._2IluPVqEWKLxoEdPZb6siI {
      width: 50%; }
      ._2iy1sIInEAjm0KdzKO5Jvz ._5akHZUiEQ6srGYNBRUUgb ._2IluPVqEWKLxoEdPZb6siI .W8KeWLt8D8Pt1yxGH1G5r {
        font-weight: bold; }

._3rlwRLmfAiulcUs85gHlZZ {
  color: #c30b24;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  padding-top: 0.3rem; }

._2rHc1JlNyECaqpGl4-URdr {
  border: none; }

i {
  font-size: 16px; }

._1rIgvk3closYSeZPOBto6x {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px; }

._1VRRmQT-7rWzuyyKewTm3Z {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px; }

._1aWujJ1O865FJG5cBt3xOB {
  font-size: 16px;
  display: flex; }
  ._1aWujJ1O865FJG5cBt3xOB ._3qi73QS6Cba-pl2yoWtReW {
    font-weight: bold;
    padding-left: 10px; }

._1yEbWqOa8wQThqtVRI6h9v {
  font-size: 0.8rem;
  padding: 10px; }

._10vlSSjDFK6y3u0ToB6W7M {
  width: 270px; }

@media (max-width: 768px) {
  ._3q_9FtmZsy5EYeinYGDUcV {
    width: 90%; } }

._1pl1_dI2q0Bo_-ELy-Zcgl {
  display: flex;
  flex-direction: column;
  padding: 10px 15px; }
  ._1pl1_dI2q0Bo_-ELy-Zcgl ._1VBSvPiwfGAGG1qpuq7vje {
    color: #005983;
    cursor: pointer;
    font-size: small;
    font-weight: bold;
    margin-top: 5px; }

._3hJYoA3N9vGiXFdzGByiAl {
  font-size: 1rem; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez {
    position: relative; }
  ._3hJYoA3N9vGiXFdzGByiAl .nntXiGxQg15WE6tFZARez:after {
    left: 6px;
    top: 1px; }

._23ZwWERYHMaroJQYLQiY4Q {
  font-size: 0.8rem;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 10px; }

._3tRJJwTivl-BPOOIlPDDPp {
  color: #eb0000;
  font-weight: 700;
  font-size: 0.85rem;
  margin-top: 10px;
  padding-left: 5px; }
