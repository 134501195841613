.lZqGHSKehF4sHR0yZ05OY {
  padding-bottom: 2rem; }

._2tTb6Xf0yZAhiX2-B0QL0Q {
  padding-bottom: 0.5rem;
  font-size: 0.9rem; }
  @media (min-width: 768px) {
    ._2tTb6Xf0yZAhiX2-B0QL0Q {
      margin-left: 5rem; } }

@media (min-width: 768px) {
  ._1GGD6jTOgdAlmSWZXGtAWw {
    margin-left: 5rem; } }

.WRZCRRJH5ra8lud9oSFcF {
  width: 50%;
  font-size: 0.9rem;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem; }
  .WRZCRRJH5ra8lud9oSFcF::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .WRZCRRJH5ra8lud9oSFcF[type='number'] {
    -moz-appearance: textfield; }
  @media (max-width: 768px) {
    .WRZCRRJH5ra8lud9oSFcF {
      width: 100%;
      margin-bottom: 1rem; } }

._3xKME3F72Ll9oASI8ogD2c {
  cursor: pointer;
  color: #337ca3;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem;
  float: right; }

._2fgvJTUEs-Fwkic-o2jmEo {
  font-weight: bold;
  background-color: #007ea8;
  color: white;
  padding: 0.6rem 0.7rem;
  font-size: 0.7rem;
  border: none;
  cursor: pointer; }
  @media (max-width: 768px) {
    ._2fgvJTUEs-Fwkic-o2jmEo {
      margin-bottom: 50px; } }

@media (min-width: 768px) {
  ._3NPbCMHLXLkzuu3qUxJXfV {
    border-top: 1px solid black;
    border-bottom: 1px solid black; } }

hr {
  border-color: #767676; }

._3diqRfm3DNDxPBhrCR3gxY {
  color: #eb0000;
  font-weight: 700;
  text-align: center; }

._3CudBApidKKJoT5nxM-hXd {
  padding-bottom: 1rem; }
  @media (min-width: 768px) {
    ._3CudBApidKKJoT5nxM-hXd {
      border-right: solid 1px #767676; } }

._3xqj9tADedCakiElLC7_9M {
  width: -webkit-fill-available; }
